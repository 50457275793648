'use strict';

angular.module('webPartnerPortalApp')
  .config(function ($routeProvider) {
    $routeProvider
      .when('/users', {
        templateUrl: 'app/users/users.html',
        controller: 'UsersCtrl',
        controllerAs: 'UsersCtrlVM',
        resolve: {
          users: function ($http) {
            return $http.get('/api/user');
          },
          roles: function ($http) {
            return $http.get('/api/user/roles');
          }
        }
      });
  });
